import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0px;
  position: relative;
  &::after {
    content: '';
    width: calc(100% - 20px);
    height: 1px;
    background: ${(props) => props.theme.greyDisabled};
    position: absolute;
    bottom: 0px;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
  &.active {
    height: 100%;
    padding: 20px 0px 0px 0px;
    justify-content: flex-start;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  .site-menu-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background: ${(props) => props.theme.greyDisabled};
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    .site-text {
      font-family: 'Prompt', sans-serif;
      font-weight: bold;
      font-size: 24px;
      color: #1f2d39;
    }
    &.active {
      border: 6px solid ${(props) => props.theme.jadeGreen};
      margin-bottom: 20px;
      cursor: default;
    }
  }
  .side-menu {
    display: none;
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.5);
    &.open-menu {
      display: block;
    }
  }
`
