const MESSAGE = {
  EN: {
    no_camera_alert_dialog_title: 'No Camera',
    no_camera_alert_dialog_message: 'There is no camera in this site.'
  },
  TH: {
    no_camera_alert_dialog_title: 'ไม่มีกล้อง',
    no_camera_alert_dialog_message: 'ไม่มีกล้องในไซต์นี้'
  }
}

export default MESSAGE
