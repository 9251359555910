/* eslint-disable indent */
import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import SiteMenuStyled from './styledComponent'
import SidebarMenu from '../SidebarMenu/Loadable'
import ModalNotification from '../ModalNotification/Loadable'
import GraphIcon from '../../asset/images/sidebar/graph-sidebar.svg'
import GraphIconActive from '../../asset/images/sidebar/graph-sidebar-active.svg'
import CameraIcon from '../../asset/images/sidebar/camera-sidebar.svg'
import CameraIconActive from '../../asset/images/sidebar/camera-sidebar-active.svg'
import ZoneIcon from '../../asset/images/sidebar/map-sidebar.svg'
import ZoneIconActive from '../../asset/images/sidebar/map-sidebar-active.svg'
import MESSAGE from './message'
import { DASHBOARD_TOOLTIP, VIOLATION_TOOLTIP, ZONE_TOOLTIP } from '../../utils'

const FEATURE_SITE = 'sites'

class SiteMenu extends React.PureComponent {
  state = {
    showAlertSiteNoCameraModal: false
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  handleSidebarMenuClick = (path) => {
    this.props.onPageChanged(path)
  }

  renderSideBarMenu() {
    const feature = this.props.location.pathname.split('/')[2]
    const projectID = this.props.location.pathname.split('/')[3]
    const siteId = this.props.location.pathname.split('/')[4]
    if (this.props.siteObject) {
      const sidemenuClassName =
        feature === FEATURE_SITE && Number(projectID) === this.props.siteObject.project_id && Number(siteId) === this.props.siteObject.site_id
          ? 'open-menu side-menu'
          : 'side-menu'
      const sidebarMenu = [
        {
          path: `/home/sites/${this.props.siteObject.project_id}/${this.props.siteObject.site_id}/dashboard`,
          imgSrc: GraphIcon,
          imgSrcActive: GraphIconActive,
          id: 'btn-dashboard',
          tooltipText: DASHBOARD_TOOLTIP,
          width: 30,
          height: 20
        },
        {
          path: `/home/sites/${this.props.siteObject.project_id}/${this.props.siteObject.site_id}/violation`,
          imgSrc: CameraIcon,
          imgSrcActive: CameraIconActive,
          id: 'btn-violation',
          tooltipText: VIOLATION_TOOLTIP,
          width: 21,
          height: 15
        },
        {
          path: `/home/sites/${this.props.siteObject.project_id}/${this.props.siteObject.site_id}/zone`,
          imgSrc: ZoneIcon,
          imgSrcActive: ZoneIconActive,
          id: 'btn-zonesetting',
          tooltipText: ZONE_TOOLTIP,
          width: 25,
          height: 22
        }
      ]
      const listMenu = sidebarMenu.map((menuObj) => {
        const isActive = this.props.location.pathname.indexOf(menuObj.path) !== -1
        return (
          <SidebarMenu
            id={menuObj.id}
            key={menuObj.path}
            path={menuObj.path}
            icon={<img src={isActive ? menuObj.imgSrcActive : menuObj.imgSrc} alt="icon" width={menuObj.width} height={menuObj.height} />}
            onClick={this.handleSidebarMenuClick}
            active={isActive}
            tooltipText={menuObj.tooltipText}
            language={this.props.language}
          />
        )
      })
      return <div className={sidemenuClassName}>{listMenu}</div>
    }
  }

  handleClicked = () => {
    if (this.props.siteObject.all_camera !== 0) {
      this.handleSidebarMenuClick(`/home/sites/${this.props.siteObject.project_id}/${this.props.siteObject.site_id}/dashboard`)
    } else {
      this.setShowAlertModalNoCameraSite()
    }
  }

  setShowAlertModalNoCameraSite = () => {
    this.setState({
      showAlertSiteNoCameraModal: true
    })
  }

  setCloseAlertModalNoCameraSite = () => {
    this.setState({
      showAlertSiteNoCameraModal: false
    })
  }

  getAlertModalSiteNoCamera() {
    let output = null
    if (this.state.showAlertSiteNoCameraModal) {
      output = (
        <ModalNotification
          title={this.getMessage('no_camera_alert_dialog_title')}
          message={this.getMessage('no_camera_alert_dialog_message')}
          onOverLayClose={this.setCloseAlertModalNoCameraSite}
        />
      )
    }
    return output
  }

  getSiteMenuClassName() {
    let className = 'site-menu'
    className += this.props.isActive ? ' active' : ''
    return className
  }

  getActiveSiteClassName() {
    let className = 'site-menu-wrapper'
    className += this.props.isActive ? ' active' : ''
    return className
  }

  render() {
    return (
      <SiteMenuStyled className={this.getSiteMenuClassName()}>
        <Tooltip title={this.props.siteObject.name.toUpperCase()} placement="right">
          <div className={this.getActiveSiteClassName()} onClick={this.handleClicked}>
            <div className="site-text">{this.props.siteObject.name.substring(0, 2).toUpperCase()}</div>
          </div>
        </Tooltip>
        {this.renderSideBarMenu()}
        {this.getAlertModalSiteNoCamera()}
      </SiteMenuStyled>
    )
  }
}

SiteMenu.propTypes = {
  siteObject: PropTypes.shape({
    name: PropTypes.string,
    project_id: PropTypes.number,
    site_id: PropTypes.number
  }),
  onPageChanged: PropTypes.func,
  language: PropTypes.string.isRequired
}

export default SiteMenu
